module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Everlast Gyms","short_name":"EverlastGyms","start_url":"/","icon":"src/images/favicon.ico","background_color":"#1C1C1C","theme_color":"#1C1C1C","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"9d7fa7dfef3541ee2b0b9d1838791539"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PPX8VKZW","includeInDevelopment":false,"gtmAuth":"YYEMAFDHh9dJogh87EuBMA","gtmPreview":"env-30","enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
